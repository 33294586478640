:root {
  --font-1: "Brice-BlackSemiCondensed";
  --font-2: "Brice-BoldSemiCondensed";
  --font-3: "Brice-LightSemiCondensed";
  --font-4: "pangram-black";
  --font-5: "PPPangramSans-Bold";
  --font-6: "pangram-regular";
  --font-7: "PPPangramSans";
  background: #eae0d7;
}

@font-face {
  font-family: Brice-BlackSemiCondensed;
  src: url("../../clients/#{$client}/fonts/Brice-BlackSemiCondensed.otf")
    format("opentype");
}

@font-face {
  font-family: Brice-BoldSemiCondensed;
  src: url("../../clients/#{$client}/fonts/Brice-BoldSemiCondensed.otf")
    format("opentype");
}

@font-face {
  font-family: Brice-LightSemiCondensed;
  src: url("../../clients/#{$client}/fonts/Brice-LightSemiCondensed.otf")
    format("opentype");
}

@font-face {
  font-family: pangram-black;
  src: url("../../clients/#{$client}/fonts/Pangram-Black.otf")
    format("opentype");
}

@font-face {
  font-family: PPPangramSans-Bold;
  src: url("../../clients/#{$client}/fonts/PPPangramSans-Bold.otf")
    format("opentype");
}

@font-face {
  font-family: PPPangramSans;
  src: url("../../clients/#{$client}/fonts/PPPangramSans.ttf")
    format("truetype");
}

@font-face {
  font-family: pangram-regular;
  src: url("../../clients/#{$client}/fonts/Pangram-Regular.otf")
    format("opentype");
}
